import homeDetailsImage1 from "./../assets/img/home-details-1.svg";
import homeDetailsImage2 from "./../assets/img/home-details-2.svg";
import homeDetailsImage3 from "./../assets/img/home-details-3.svg";
import arrowLeft from "./../assets/img/arrow-left.svg";
import arrowRight from "./../assets/img/arrow-right.svg";
import { useCallback, useEffect, useRef, useState } from "react";

export function ExtraHomeDetails() {
  const scrollableDetails = useRef<HTMLDivElement>(null);
  const detailsCard1 = useRef<HTMLDivElement>(null);
  const detailsCard2 = useRef<HTMLDivElement>(null);
  const detailsCard3 = useRef<HTMLDivElement>(null);
  const [currentCard, setCurrentCard] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const allCards = [detailsCard1, detailsCard2, detailsCard3];

  const recenterCard = (cardIndex) => {
    if (scrollableDetails.current && allCards[cardIndex] && allCards[cardIndex].current) {
      const scrollLeft = scrollableDetails.current.scrollLeft;
      const cardStart = allCards[cardIndex].current.offsetLeft;
      const cardWidth = allCards[cardIndex].current.clientWidth;

      const scrollTo = cardStart - scrollLeft - (scrollableDetails.current.clientWidth / 2) + (cardWidth / 2);
      scrollableDetails.current.scrollBy({ left: scrollTo, top: 0, behavior: 'smooth' });
    }
  };

  const scrollLeft = useCallback(() => {
    if (isScrolling) return;

    setIsScrolling(true);
    let newCurrentCard = currentCard > 0 ? (currentCard - 1) % allCards.length : currentCard;
    recenterCard(newCurrentCard);
    
    setTimeout(() => setIsScrolling(false), 500); // Adjust time as per your scroll duration
    setCurrentCard(newCurrentCard);
  }, [isScrolling, currentCard, allCards]);

  const scrollRight = useCallback(() => {
    if (isScrolling) return;

    setIsScrolling(true);
    let newCurrentCard = currentCard < allCards.length - 1 ? (currentCard + 1) % allCards.length : currentCard;
    recenterCard(newCurrentCard);
    
    setTimeout(() => setIsScrolling(false), 500); // Adjust time as per your scroll duration
    setCurrentCard(newCurrentCard);
  }, [isScrolling, currentCard, allCards]);

  useEffect(() => {
    const interval = setInterval(scrollRight, 3000);

    return () => clearInterval(interval);
  }, [scrollRight]);

  useEffect(() => {
    const interval = setInterval(function() {scrollLeft(); scrollLeft(); scrollLeft();}, 9000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-100 p-relative">
      <div ref={scrollableDetails} className="extra-details-container">
        <div ref={detailsCard1} className="extra-details">
          <img
            src={homeDetailsImage1}
            className="extra-details__image"
            alt="Thriving on the limitless possibilities"></img>
          <div className="extra-details__content border-top">
            <div className="mb-2">
              <b>Thriving on the limitless possibilities</b>
            </div>
            <div>Created by connecting leading blockchain networks</div>
          </div>
        </div>
        <div ref={detailsCard2} className="extra-details">
          <img
            src={homeDetailsImage2}
            className="extra-details__image"
            alt="First multichain protocol owned treasury"></img>
          <div className="extra-details__content border-top">
            <div className="mb-2">
              <b>First multichain protocol owned treasury</b>
            </div>
            <div>Designed for generating value continuously</div>
          </div>
        </div>
        <div ref={detailsCard3} className="extra-details">
          <img src={homeDetailsImage3} className="extra-details__image" alt="Interactive Staking"></img>
          <div className="extra-details__content border-top">
            <div className="mb-2">
              <b>Interactive Staking</b>
            </div>
            <div>Unique design encouraging active participation and engagement with the network</div>
          </div>
        </div>
      </div>
      <div className="arrows-container">
        <div className="arrows">
          <div className={`arrows__left`} onClick={() => scrollLeft()}>
            <img src={arrowLeft} alt="scroll-left"></img>
          </div>
          <div className={`arrows__right`} onClick={() => scrollRight()}>
            <img src={arrowRight} alt="scroll-right"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

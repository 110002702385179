const constants = {
  //
  // PROD ENV CONSTANTS
  //
  officialMetamaskExtensionUrl: "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
  isMainNet: true,
  isDevNet: false,
  isTestNet: false,
  defaultExternalChainId: 888,
  isTestNetButtonDisabled: true,
  isRegisterButtonDisabled: true,
  nuumURL: "https://faucet.nuum.network/requestEth",
  externalAvailableNetworks: [
    {
      chainId: 1,
      name: "Ethereum Mainnet",
      isAvailable: true,
    },
    {
      chainId: 11155111,
      name: "Sepolia",
      isAvailable: true,
    },
    {
      chainId: 888,
      name: "Continuum Testnet",
      rpcUrl: "https://rpc.nuum.network/",
      isAvailable: true,
    },
  ],

  externalAvailableTokens: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      isAvailable: true,
    },
  ],
};

//
// TEST ENV CONSTANTS
//
const testConstants = {
  ...constants,
  isMainNet: false,
  isDevNet: false,
  isTestNet: true,
};

//
// DEV ENV CONSTANTS
//
const developmentConstants = {
  ...constants,
  isMainNet: false,
  isDevNet: true,
  isTestNet: false,
};

let exportedConstants = constants;

console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log("process.env.REACT_APP_NETWORK_ENV", process.env.REACT_APP_NETWORK_ENV);

if (process.env.REACT_APP_NETWORK_ENV === "production" || process.env.REACT_APP_NETWORK_ENV === "staging")
  exportedConstants = constants;
if (process.env.REACT_APP_NETWORK_ENV === "development") exportedConstants = developmentConstants;
if (process.env.REACT_APP_NETWORK_ENV === "test") exportedConstants = testConstants;

export default exportedConstants;

import { createSlice } from "@reduxjs/toolkit";

export type WalletInfo = {
  address: string;
  chainId: number;
  nodeUrl?: string;
};

const initialState: WalletInfo = {
  address: "",
  chainId: 1,
  nodeUrl: "",
};

export const walletSlice = createSlice({
  name: "walletInfo",
  initialState,
  reducers: {
    resetWalletState: (state) => {
      state = initialState;
      return state;
    },
    storeWalletInfo: (state, action) => {
      state = action.payload;
      return state;
    },
    storeAddress: (state, action) => {
      state.address = action.payload;
    },
    storeNodeUrl: (state, action) => {
      state.nodeUrl = action.payload;
    },
    storeChainId: (state, action) => {
      state.chainId = action.payload;
    },
  },
});

export const { resetWalletState, storeWalletInfo, storeAddress, storeNodeUrl, storeChainId } = walletSlice.actions;

export default walletSlice.reducer;

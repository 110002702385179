import { ethers } from "ethers-ts";
import constants from "./constants";

export type simpleNetworkType = {
  chainId: number;
  name: string;
  isAvailable: boolean;
};

export const validateExternalNetwork = async (
  provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider,
  validNetworks: simpleNetworkType[],
  currentChainId = -1
) => {
  console.log("validNetworks", validNetworks);

  if (currentChainId === -1) {
    currentChainId = (await provider.getNetwork()).chainId;
  }
  console.log("Wallet network info", currentChainId);

  const validChainsString = validNetworks
    .filter((v) => v.isAvailable)
    .map((v) => v.name + "(" + v.chainId + ")")
    .toString()
    .replace(",", ", ");

  if (constants.isDevNet) {
    if (!validNetworks.find((net: simpleNetworkType) => net.chainId === currentChainId)) {
      throw `Please select one of these chains from your Wallet: ${validChainsString}`;
    }
  }
  if (constants.isTestNet) {
    if (!validNetworks.find((net: simpleNetworkType) => net.chainId === currentChainId)) {
      throw `Please select one of these chains from your Wallet: ${validChainsString}`;
    }
  }
  if (constants.isMainNet) {
    if (!validNetworks.find((net: simpleNetworkType) => net.chainId === currentChainId)) {
      throw `Please select one of these chains from your Wallet: ${validChainsString}`;
    }
  }
};

export const deleteRecentWalletsFromLocalStorage = () => {
  localStorage.removeItem("WCM_RECENT_WALLET_DATA");
};

export const checkMetamaskAvailability = () => {
  if (!(typeof window.ethereum !== "undefined")) {
    // @ts-ignore
    if (!!window.chrome) {
      setTimeout(() => {
        const newWindow = window.open(constants.officialMetamaskExtensionUrl, "_blank");
        if (newWindow) newWindow.opener = null;
      }, 2500);
      throw "Please install Metamask. Redirecting to extension...";
    } else {
      throw "Please install Metamask Extension.";
    }
  } else {
    if (!window?.ethereum?.isMetaMask) {
      // @ts-ignore
      if (!!window.chrome) {
        setTimeout(() => {
          const newWindow = window.open(constants.officialMetamaskExtensionUrl, "_blank");
          if (newWindow) newWindow.opener = null;
        }, 2500);
        throw "Please install Metamask. Redirecting to extension...";
      } else {
        throw "Please install Metamask Extension.";
      }
    }
  }
};

import { createSlice } from "@reduxjs/toolkit";

export enum flowSteps {
  Home = 0,
  Connect = 1,
  AddNetwork = 2,
  Faucet = 3,
}

export type flowState = {
  currentFlowStep: number;
};

const initialState: flowState = {
  currentFlowStep: 0,
};

export const wizardStatusSlice = createSlice({
  name: "wizardStatus",
  initialState,
  reducers: {
    resetWizardStatusState: (state) => {
      state = initialState;
      return state;
    },
    storeCurrentWizardFlowStep: (state, action) => {
      console.log("storeCurrentWizardFlowStep", action.payload);
      if (state.currentFlowStep !== action.payload) {
        state.currentFlowStep = action.payload;
      }
    },
  },
});

export const { resetWizardStatusState, storeCurrentWizardFlowStep } = wizardStatusSlice.actions;

export default wizardStatusSlice.reducer;

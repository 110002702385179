import { configureStore } from "@reduxjs/toolkit";
import walletReducer from "./walletSlice";
import wizardStatusReducer from "./wizardStatusSlice";

export const store = configureStore({
  reducer: {
    walletInfo: walletReducer,
    wizardStatus: wizardStatusReducer,
  },
});

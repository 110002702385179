import { useContext, useEffect, useRef, useState } from "react";
import "./home.scss";
import Lottie from "react-lottie-player";
import desktopAnim from "./../../assets/img/animations/continuum_animation.json";
import mobileAnim from "./../../assets/img/animations/continuum_animation.json";
import heroLine from "./../../assets/img/hero_line.svg";
import fadeLogo from "./../../assets/img/fade_logo.svg";
import continuumLogo from "./../../assets/img/continuum_logo.svg";
import stepsLine from "./../../assets/img/steps_line.png";
import copyIcon from "./../../assets/img/copy_icon.svg";
import { ExtensionConnect } from "../../components/extensionConnect";
import { useSelector } from "react-redux";
import { externalNetworkProviderTypes } from "../../services/hooks/externalNetwork-provider/externalNetworkContext";
import { SpinnerContext } from "../../services/hooks/spinner/spinnerContext";
import useExternalNetwork from "../../services/hooks/externalNetwork-provider/useExternalNetwork";
import exportedConstants from "../../utils/constants";
import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";
import { ExtraHomeDetails } from "../../components/extra-home-details";

const Home = () => {
  const body: any = useRef();
  const loading: any = useRef();
  const container: any = useRef();
  const loader: any = useRef();
  const player: any = useRef();
  const wrapper: any = useRef();
  const hero: any = useRef();
  const steps: any = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [lottieJson, setLottieJson] = useState(desktopAnim);
  const walletInfo = useSelector((state: any) => state.walletInfo);
  const { handleSpinner } = useContext(SpinnerContext);
  const { externalNetworkClient } = useExternalNetwork();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [comingSoonButtonLabel, setComingSoonButtonLabel] = useState("[ COMING SOON ]");
  const [testnetButtonLabel, setTestnetButtonLabel] = useState("// JOIN TESTNET //");
  const [registerButtonLabel, setRegisterButtonLabel] = useState("REGISTER FOR PUBLIC GENERATION EVENT");

  const claimFaucet = async () => {
    const showSpinner = handleSpinner(
      <>
        <div className="text-bold text-center mb-5 mt-4">Claiming NUUM from faucet</div>
      </>,
      "spinner-root"
    );

    const url = exportedConstants.nuumURL;

    try {
      showSpinner(true);
      if (!walletInfo?.address) throw new Error("Error fetching address");
      const api: AxiosInstance = axios.create({
        baseURL: url,
        timeout: 10000, // 10 secs
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const response = await api.post(url, {
        address: walletInfo.address,
      });
      console.log("response", response);

      let parsedResponse = "NUUM claimed successfully";

      if (response?.data) parsedResponse = response?.data;

      toast(`${parsedResponse}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "light",
      });
    } catch (err) {
      console.error(err);
      let parsedError = err;
      if (err?.response?.data) parsedError = err?.response?.data;

      toast(`${parsedError}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
        theme: "light",
      });
    } finally {
      showSpinner(false);
    }
  };

  const switchNetwork = async () => {
    const showSpinner = handleSpinner(
      <>
        <div className="text-bold text-center mb-5 mt-4">Please confirm in your wallet</div>
      </>,
      "spinner-root"
    );
    try {
      console.log("requestAddNetwork");

      await externalNetworkClient.requestAddNetwork({
        chainId: 888,
        chainName: "Continuum Testnet",
        rpcUrls: ["https://rpc.nuum.network/"],
        nativeCurrency: {
          name: "Continuum",
          symbol: "NUUM",
          decimals: 18,
        },
      });
      openStep(null, "three", document.getElementById("button-step-3"));
    } catch (err) {
      // This may mean that the user already has the network added
      // if(walletInfo.chainId !== 1287){

      try {
        console.log("requestAddNetwork err");
        await externalNetworkClient.requestNetworkSwitch(1287);
        console.log("Added network");
        openStep(null, "three", document.getElementById("button-step-3"));
      } catch (err) {
        console.error(err);
      }

      // }
    } finally {
      showSpinner(false);
    }
  };

  const onPlayerLoad = (args: any) => {
    console.log("onPlayerLoad", args);
    if ((sessionStorage.getItem("visited") as any) === "yes") {
      body.current?.classList?.remove("animateBody");
      wrapper.current?.classList.remove("hideBody");

      loader.current.style.display = "none";
    } else {
      body.current?.classList?.add("animateBody");

      loader.current.style.display = "block";
      container.current?.classList?.remove("hidden");

      setTimeout(() => {
        player?.classList?.remove("invisible");
        loading.current.style.display = "none";
        setIsPlaying(true);
      }, 250);

      sessionStorage.setItem("visited", "yes");
    }
  };

  const onPlayerComplete = () => {
    console.log("complete");
    setIsPlaying(false);
    wrapper.current.classList.remove("hideBody");
    loader.current.classList.add("fadeOut");

    setTimeout(() => {
      body.current?.classList?.remove("animateBody");
      loader.current.style.display = "none";
    }, 500);
  };

  useEffect(() => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      setLottieJson(mobileAnim);
    } else {
      setLottieJson(desktopAnim);
    }
    console.log("lottieJson", lottieJson);

    document.addEventListener("wheel", checkScrollDirection);
    document.addEventListener("touchmove", checkScrollDirectionMobile);
  }, []);

  const checkScrollDirectionMobile = () => {
    let previousScrollPosition = 0;

    const isScrollingDown = () => {
      let goingDown = false;
      let scrollPosition = window.scrollY;

      if (scrollPosition > previousScrollPosition) {
        goingDown = true;
      }

      previousScrollPosition = scrollPosition;
      return goingDown;
    };

    if (isScrollingDown()) {
      console.log("Up");
      goToLanding();
    } else {
      console.log("Down");
      goToSteps();
    }
  };

  const checkScrollDirection = (event: any) => {
    if (checkScrollDirectionIsUp(event)) {
      console.log("Up");
      goToLanding();
    } else {
      console.log("Down");
      goToSteps();
    }
  };

  const onWalletConnected = () => {
    setIsWalletConnected(true);
    openStep(null, "two", document.getElementById("button-step-2"), true);
  };

  const goToLanding = () => {
    if (exportedConstants.isTestNetButtonDisabled) {
    } else {
      body.current?.classList?.remove("scrolled");
      steps.current?.classList?.remove("active");
      steps.current?.classList?.remove("slide-up");
      hero.current?.classList?.add("active");
      hero.current?.classList?.add("slide-down");
    }
  };

  const goToSteps = () => {
    if (exportedConstants.isTestNetButtonDisabled) {
      // setTestnetButtonLabel("[ COMING SOON ]");
    } else {
      body.current?.classList?.add("scrolled");
      hero.current?.classList?.remove("active");
      hero.current?.classList?.remove("slide-down");
      steps.current?.classList?.add("active");
      steps.current?.classList?.add("slide-up");
    }
  };

  const clickedJoinTestnet = () => {
    if (exportedConstants.isTestNetButtonDisabled) {
      setTestnetButtonLabel(comingSoonButtonLabel);
      setTimeout(function () {
        setTestnetButtonLabel(testnetButtonLabel);
      }, 3000);
    }
    // goToSteps();
  };

  const clickedRegister = () => {
    if (exportedConstants.isRegisterButtonDisabled) {
      setRegisterButtonLabel(comingSoonButtonLabel);
      setTimeout(function () {
        setRegisterButtonLabel(registerButtonLabel);
      }, 3000);
    }
    // goToSteps();
  };

  const checkScrollDirectionIsUp = (event: any) => {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  };

  const activateStep = (evt: any, step: any, element?: any) => {
    let i, x, tablinks;
    x = document.getElementsByClassName("steps__content") as any;
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablink") as any;
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    (document.getElementById(step) as any).style.display = "flex";
    if (evt) {
      evt.currentTarget.className += " active";
    } else {
      element.className += " active";
    }
  };

  const openStep = async (evt: any, step: any, element?: any, useForce = false) => {
    if (useForce) {
      activateStep(evt, step, element);
      return;
    }
    switch (step) {
      case "one": {
        activateStep(evt, step, element);
        break;
      }
      case "two": {
        if (isWalletConnected) activateStep(evt, step, element);
        break;
      }
      case "three": {
        try {
          const chainId = await externalNetworkClient.getChainId();
          console.log("chainId", chainId);
          console.log("isWalletConnected", isWalletConnected);
          console.log("walletInfo", walletInfo);
          if (
            isWalletConnected &&
            walletInfo?.address?.length !== 0 &&
            chainId === exportedConstants.defaultExternalChainId
          )
            activateStep(evt, step, element);
        } catch (err) {
          console.error(err);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const copyFunction = () => {
    let copyText = document.getElementById("myInput");
    console.log(copyText?.innerText);
    navigator.clipboard.writeText(copyText?.innerText || "");
    let tooltip = document.getElementById("myTooltip") as any;
    tooltip.innerHTML = "Copied!";
  };

  const outFunc = () => {
    let tooltip = document.getElementById("myTooltip") as any;
    tooltip.innerHTML = "Copy to clipboard";
  };

  return (
    <div ref={body} id="body" className="home-body animateBody">
      <div ref={loader} id="loader" className="custom-container fadeIn">
        <div ref={loading} id="loading" className="lds-dual-ring"></div>
        <div ref={container} id="container" className="container hidden">
          <Lottie
            id="player"
            ref={player}
            loop
            animationData={lottieJson}
            className="invisible"
            renderer="svg"
            speed={1.5}
            play={isPlaying}
            onLoad={onPlayerLoad}
            onLoopComplete={onPlayerComplete}
          />
        </div>
      </div>

      <header className="header">
        <nav className="navbar pt-5">
          <span className="spacer"></span>
          <div>
            <a href="/" className="primary" target="_blank" rel="noopener nofollow noreferrer">
              Reach continuum
            </a>
            <p className="pt-3 footer__text">[ Coming soon ]</p>
          </div>
        </nav>
      </header>

      <main ref={wrapper} className="main">
        <section ref={hero} className="hero active">
          {/* <img className="hero__line" src={heroLine} alt="line" /> */}
          <div className="hero__line-css">
            <div className="hero__line-css__vertical" />
          </div>
          <img className="hero__small-logo" src={fadeLogo} alt="small logo" />
          <img className="hero__logo" src={continuumLogo} alt="logo" />
          <p className="hero__text">
            <div className="d-flex align-items-center justify-content-center">
              {`// Ultimate interoperability.`}
              <span className="break-words pl-2">{` //`}</span>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <span className="break-words pr-2">{`// `}</span>
              {`Multichain powered. //`}
            </div>
          </p>
          <div>
            <div className="hero__buttons">
              <a href="/res/pitchdeck.pdf" className="secondary" target="_blank" rel="noopener nofollow noreferrer">
                Pitchdeck
              </a>
              <a href="/res/lightpaper.pdf" className="secondary" target="_blank" rel="noopener nofollow noreferrer">
                Lightpaper
              </a>
            </div>
            <button type="button" className="link join-button" onClick={() => clickedJoinTestnet()}>
              {testnetButtonLabel}
            </button>
          </div>
          <ExtraHomeDetails />
        </section>
        <section ref={steps} className="steps ">
          <div className="steps__header">
            <img className="steps__line" src={stepsLine} alt="line" />
            <ul className="steps__list">
              <li>
                <button id="button-step-1" type="button" className="tablink active" onClick={(e) => openStep(e, "one")}>
                  I
                </button>
              </li>
              <li>
                <button id="button-step-2" type="button" className="tablink" onClick={(e) => openStep(e, "two")}>
                  II
                </button>
              </li>
              <li>
                <button id="button-step-3" type="button" className="tablink" onClick={(e) => openStep(e, "three")}>
                  III
                </button>
              </li>
            </ul>
          </div>
          <div className="steps__container">
            <div id="one" className="steps__content">
              <p className="steps__text">{`// connect wallet //`}</p>
              <ExtensionConnect onConnected={() => onWalletConnected()} />
              {/* <button type="button" className="link connect-button">
                connect
              </button> */}
            </div>

            <div id="two" className="steps__content" style={{ display: "none" }}>
              <p className="steps__text">{`// add network //`}</p>
              <button type="button" className="link add-network-button" onClick={() => switchNetwork()}>
                add network
              </button>
            </div>

            <div id="three" className="steps__content" style={{ display: "none" }}>
              <p className="steps__text">{`// faucet //`}</p>
              <div className="link steps__copy">
                <span id="myInput">{walletInfo?.address || "0xYourAddress"}</span>
                <div className="tooltip">
                  <button onClick={() => copyFunction()} onMouseOut={() => outFunc()}>
                    <span className="tooltiptext" id="myTooltip">
                      Copy to clipboard
                    </span>
                    <img src={copyIcon} alt="copy icon" />
                  </button>
                </div>
              </div>
              <button type="button" className="primary claim" onClick={() => claimFaucet()}>
                claim
              </button>
            </div>
          </div>
        </section>
      </main>

      <div className="footer">
        <button type="button" className="link register-button text-black" onClick={() => clickedRegister()}>
          {registerButtonLabel}
        </button>

        <ul className="footer__social">
          <li>
            <a
              href="https://twitter.com/c8ntinuum"
              className="footer__social-icon icon-x"
              target="_blank"
              rel="noopener nofollow noreferrer">
              {" "}
            </a>
          </li>
          <li>
            <a
              href="https://t.me/c8ntinuum"
              className="footer__social-icon icon-telegram"
              target="_blank"
              rel="noopener nofollow noreferrer">
              {" "}
            </a>
          </li>
          <li>
            <a
              href="#"
              className="footer__social-icon icon-discord"
              target="_blank"
              rel="noopener nofollow noreferrer">
              {" "}
            </a>
          </li>
        </ul>
        <p className="footer__text">[ Coming soon ]</p>
      </div>
    </div>
  );
};

export default Home;

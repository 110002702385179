import { Outlet } from "react-router-dom";
import { ExternalNetworkProvider } from "../../services/hooks/externalNetwork-provider/externalNetworkContext";
import { SpinnerProvider } from "../../services/hooks/spinner/spinnerContext";
import "./mainLayout.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {
  return (
    <div className="main-layout">
      <SpinnerProvider>
        <ExternalNetworkProvider>
          <Outlet />
          <ToastContainer />
        </ExternalNetworkProvider>
      </SpinnerProvider>

      <div id="spinner-root"></div>
    </div>
  );
};

export default MainLayout;

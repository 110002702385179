import { toast } from "react-toastify";
import { storeAddress } from "../services/redux/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import useExternalNetwork from "../services/hooks/externalNetwork-provider/useExternalNetwork";
import { externalNetworkProviderTypes } from "../services/hooks/externalNetwork-provider/externalNetworkContext";
import globalConstants from "../utils/constants";
import { useContext, useState } from "react";
import { SpinnerContext } from "../services/hooks/spinner/spinnerContext";
import metamaskLogo from "./../assets/logos/metamask.svg";
import walletConnectLogo from "./../assets/logos/walletConnect.svg";

export function ExtensionConnect({ onConnected = () => {} }) {
  const dispatch = useDispatch();
  const { externalNetworkClient } = useExternalNetwork();
  const { handleSpinner } = useContext(SpinnerContext);
  const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);
  const walletInfo = useSelector((state: any) => state.walletInfo);

  const getMetamaskAddress = async () => {
    const provider = await externalNetworkClient.getProvider(externalNetworkProviderTypes.metamask);
    console.log("provider", provider);

    const metamaskCurrentChainId = (await provider.getNetwork())?.chainId;
    // await validateExternalNetwork(provider, globalConstants.externalAvailableNetworks, metamaskCurrentChainId);

    const accounts = await provider.send("eth_requestAccounts", []);
    console.log("accounts", accounts);

    return {
      provider: provider,
      address: accounts[0],
      chainId: metamaskCurrentChainId,
    };
  };

  const onModalDismiss = (spinnerHandle: any, reason?: string) => {
    console.log("Wallet connect modal closed", reason);
    spinnerHandle(false);
  };

  // const testSend = async (providerType: externalNetworkProviderTypes) => {
  //   const showSpinner = handleSpinner(
  //     <>
  //       <div className="text-bold text-center mb-5 mt-4">Waiting approval from wallet</div>
  //     </>,
  //     "spinner-root"
  //   );
  //   try {
  //     // await externalNetworkClient.requestNetworkSwitch(exportedConstants.defaultExternalChainId);

  //     const trDetails: transactionParams = {
  //       from: walletInfo?.address,
  //       to: walletInfo?.address,
  //       value: "100",
  //       gasLimit: "0x5028",
  //       maxPriorityFeePerGas: "0x3b9aca00",
  //       maxFeePerGas: "0x2540be400",
  //     };
  //     console.log("trDetails", trDetails);
  //     await externalNetworkClient.sendTransaction(trDetails);
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     showSpinner(false);
  //   }
  // };

  const connectToExternalNetwork = async (providerType: externalNetworkProviderTypes) => {
    const showSpinner = handleSpinner(
      <>
        <div className="text-bold text-center mb-5 mt-4">Please confirm in your wallet</div>
      </>,
      "spinner-root"
    );
    console.log("connecting metamask");
    try {
      showSpinner(true);
      let externalNetworkAddress = "";
      let externalNetworkChainId: number;
      let currentToken: any;

      if (providerType === externalNetworkProviderTypes.metamask) {
        const externalNetworkDetails = await getMetamaskAddress();
        externalNetworkAddress = externalNetworkDetails.address;
        externalNetworkChainId = externalNetworkDetails.chainId;
        // provider = externalNetworkDetails.provider;

        currentToken = globalConstants.externalAvailableTokens.find((tok: any) => tok.isAvailable);

        await externalNetworkClient.init(externalNetworkProviderTypes.metamask);
        await externalNetworkClient.connect(externalNetworkProviderTypes.metamask);

        const _provider = await externalNetworkClient.getProvider(
          externalNetworkProviderTypes.metamask,
          externalNetworkChainId
        );
        console.log("Web3Provider provider", _provider);

        const eipInfo = await externalNetworkClient.getWalletInfo(_provider, externalNetworkProviderTypes.metamask);
        console.log("eipInfo", eipInfo);
      } else {
        if (providerType === externalNetworkProviderTypes.walletConnect) {
          // ToDo: treat the case where the user closes the walletConnect Modal
          // console.log("old provider", new ethers.providers.Web3Provider(window.ethereum));

          // const provider = await externalNetworkClient.getProvider(providerType);
          // console.log("WC provider", provider);

          // const enabled = provider.enable();
          // console.log("WC enabled", enabled);
          // console.log("WC provider", provider);

          // const connected = provider.connect();
          // console.log("WC connected", connected);
          // console.log("WC provider", provider);

          console.log("externalNetworkClient", externalNetworkClient);
          await externalNetworkClient.init(externalNetworkProviderTypes.walletConnect);

          //
          // ToDo: solve the fact that the change events are registered without a provider here.
          // And can't have the updated provider when called
          //
          const connectionInfo = await externalNetworkClient.connect(
            externalNetworkProviderTypes.walletConnect,
            onModalDismiss(showSpinner)
          );
          console.log("externalNetworkClient - connected");
          console.log("connectionInfo", connectionInfo);
          externalNetworkChainId = connectionInfo.chainId;

          const provider = await externalNetworkClient.getProvider(
            externalNetworkProviderTypes.walletConnect,
            externalNetworkChainId
          );
          console.log("JsonRpcProvider provider", provider);

          const eipInfo = await externalNetworkClient.getWalletInfo(
            provider,
            externalNetworkProviderTypes.walletConnect
          );
          console.log("eipInfo", eipInfo);

          // const firstAccount = Object.keys(eipInfo)[0];
          // const firstAccountAddress = firstAccount.split(":")[2];
          // const firstAccountChainId = parseInt(firstAccount.split(":")[1]);

          //
          // IMPORTANT TO DO: DELETE THIS AND USE THE PROPER CHAIN ID
          //
          // externalNetworkChainId = eipInfo.chainId;
          // externalNetworkChainId = parseInt(firstAccount.split(":")[1]);

          currentToken = globalConstants.externalAvailableTokens.find((tok: any) => tok.isAvailable);

          externalNetworkAddress = connectionInfo.address;

          console.log("chainId", externalNetworkChainId);
          console.log("externalNetworkAddress", externalNetworkAddress);
          console.log("currentToken", currentToken);
        }
      }

      dispatch(storeAddress(externalNetworkAddress?.toLowerCase()));
      setIsMetamaskConnected(true);
      onConnected();
    } catch (err: any) {
      console.error(err);

      let readableError = err;
      if (err?.message) {
        readableError = err?.message;
      }
      readableError =
        readableError?.split(`"Error:`)?.pop()?.split(`"`)[0] ||
        readableError?.split(`'Error:`)?.pop()?.split(`'`)[0] ||
        "";

      toast(readableError + "", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
        theme: "light",
      });
      return err;
    } finally {
      showSpinner(false);
    }
  };

  return (
    <div>
      <div className="d-flex gap-2 flex-wrap w-100 justify-content-center align-items-center">
        <div
          onClick={() => connectToExternalNetwork(externalNetworkProviderTypes.metamask)}
          className={`p-relative d-flex align-items-center ${
            isMetamaskConnected && externalNetworkClient.providerType ? "disabled" : ""
          }
               ${
                 isMetamaskConnected && externalNetworkClient.providerType === externalNetworkProviderTypes.metamask
                   ? "primary soft-disabled"
                   : "secondary"
               }`}>
          <img
            alt="step-logo"
            className="mr-2"
            style={{ maxWidth: "24px", maxHeight: "24px" }}
            src={metamaskLogo}></img>
          <div>Metamask</div>
        </div>
        <div className="">or</div>
        <div
          onClick={() => connectToExternalNetwork(externalNetworkProviderTypes.walletConnect)}
          className={`p-relative d-flex align-items-center ${
            isMetamaskConnected && externalNetworkClient.providerType ? "disabled" : ""
          }
               ${
                 isMetamaskConnected &&
                 externalNetworkClient.providerType === externalNetworkProviderTypes.walletConnect
                   ? "primary soft-disabled"
                   : "secondary"
               }`}>
          <img
            alt="step-logo"
            className="mr-2 m-1"
            style={{ maxWidth: "24px", maxHeight: "24px" }}
            src={walletConnectLogo}></img>
          <div>WalletConnect</div>
        </div>
      </div>
    </div>
  );
}

import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import MainLayout from "./layouts/mainLayout/mainLayout";
import { Provider } from "react-redux";
import { store } from "./services/redux/store";
import AccessTestnet from "./pages/access-testnet/access-testnet";
import Home from "./pages/home/home";

function App() {
  return (
    <div className="App">
      <Router>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="/testnet" element={<AccessTestnet />} />
            </Route>
          </Routes>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
